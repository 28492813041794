import * as React from "react"
import { Link, HeadFC } from "gatsby"
import Layout from "../components/layout/layout"
import Policy from "../components/policy/policy"
import SEO from "../components/seo/seo"

const TermsPage = () => {
  return (
    <Layout chat={true}>
        <Policy>
        <div
    className="terms max-container text-body-2 xl:text-body-1 px-8 md:px-16 lg:px-16 xl:px-24 py-4 md:py-8 lg:py-8 xl:py-12"
  >
    <h2>Terms of Use</h2>

    <p className="c25">
      <span className="c4 c10 c21"
        >Effective Date / Date of Last Revision: &nbsp;May 24, 2022</span>
    </p>
    <p className="c8"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_7-0 start" start="1">
      <li className="c9 c14 li-bullet-0">
        <span className="c6 c2">WHO WE ARE. &nbsp;</span>
      </li>
    </ol>
    <p className="c7" id="h.30j0zll"><span className="c6 c4"></span></p>
    <p className="c29" id="h.1fob9te">
      <span className="c4">HomeFounder (&ldquo;</span
      ><span className="c2">HomeFounder</span
      ><span className="c4">,&rdquo; &ldquo;</span><span className="c2">we</span
      ><span className="c4">,&rdquo; &ldquo;</span><span className="c2">our</span
      ><span className="c4">,&rdquo; or &ldquo;</span><span className="c2">us</span
      ><span className="c4"
        >&rdquo;) is an innovative startup program designed to save home buyers
        money when purchasing new construction homes. &nbsp;HomeFounder brings
        together qualified homebuyers early in the land acquisition, development
        and construction phase and allows them to directly invest in their
        community without outside investors. &nbsp;HomeFounder has launched a
        publicly available website at </span
      ><span className="c13 c4"
        ><a className="c5" href="https://www.homefounder.com"
          >www.homefounder.com</a
        ></span
      ><span className="c4">&nbsp;(&ldquo;</span><span className="c2">Site</span
      ><span className="c4"
        >&rdquo;) where individuals may obtain more information about
        HomeFounder&rsquo;s projects and services (&ldquo;</span
      ><span className="c2">Services</span
      ><span className="c6 c4">&rdquo;). &nbsp;</span>
    </p>
    <ol className="c0 lst-kix_list_7-0" start="2">
      <li className="c9 c14 li-bullet-0">
        <span className="c6 c2">THESE TERMS FORM A BINDING LEGAL AGREEMENT.</span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c4">These Terms of Use (&quot;</span
      ><span className="c2">Terms of Use</span
      ><span className="c4">&quot;), along with our </span
      ><span className="c13 c4"
        ><router-link to="/privacy">Privacy Policy</router-link></span
      ><span className="c4">&nbsp;(&ldquo;</span
      ><span className="c2">Privacy Policy</span
      ><span className="c6 c4"
        >&rdquo;), constitute a legally binding agreement between you and
        HomeFounder. Please read the Terms of Use and Privacy Policy carefully.
        Your access to and use of our Site and Services is subject to these
        Terms of Use, the Privacy Policy and all applicable laws. &nbsp;By
        accessing and using our Site and our Services in any manner, you agree
        to be bound by these Terms of Use. &nbsp;If you do not agree to adhere
        to these Terms of Use or the Privacy Policy, then you may not use our
        Site or Services.
      </span>
    </p>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >We reserve the right to change these Terms of Use and our Privacy
        Policy. &nbsp;We may also change, suspend, or end access to all or any
        part of the Site and Services at any time, in our discretion.</span
      >
    </p>
    <p className="c7"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="3">
      <li className="c9 c14 li-bullet-0">
        <span className="c6 c2">YOUR REPRESENTATIONS. </span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >BY USING AND/OR ACCESSING ANY PART OF OUR SITE OR SERVICES, YOU
        UNCONDITIONALLY AND EXPRESSLY ACKNOWLEDGE, REPRESENT AND AGREE THAT YOU:
        (A) ARE OVER 18 YEARS OF AGE; (B) HAVE READ AND UNDERSTAND THESE TERMS
        OF USE AND THE PRIVACY POLICY; (C) UNDERSTAND THAT YOU ARE BOUND BY
        THESE TERMS OF USE AND THE PRIVACY POLICY; AND (D) WILL COMPLY WITH
        THESE TERMS OF USE, THE PRIVACY POLICY AND ANY APPLICABLE LAWS AND
        REGULATIONS.</span
      >
    </p>
    <p className="c7"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="4">
      <li className="c9 c14 li-bullet-0">
        <span className="c6 c2">LICENSE TO USE THE SITE AND SERVICES.</span
        ><span className="c6 c4">&nbsp; </span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >Subject to these Terms of Use and the Privacy Policy, HomeFounder
        grants you a limited, non-transferable license to access and use the
        functionality, features, content and information provided through our
        Site and/or Services for the purposes of obtaining information about our
        projects and home buying opportunities. You may not use the Site or
        Services for any other purpose without first obtaining written
        permission from HomeFounder.</span
      >
    </p>
    <p className="c7 c27"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="5">
      <li className="c9 c14 li-bullet-0">
        <span className="c2">OWNERSHIP RIGHTS.</span
        ><span className="c6 c4">&nbsp; </span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >You expressly acknowledge and agree that HomeFounder and its content
        providers own all content, trademarks, copyrights and other intellectual
        property located on and related to the Site and Services. &nbsp;
      </span>
    </p>
    <p className="c8 c30"><span className="c6 c2"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="6">
      <li className="c9 c14 li-bullet-0">
        <span className="c2">ACCOUNT REGISTRATION. &nbsp;</span>
      </li>
    </ol>
    <p className="c7"><span className="c2 c6"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >To access and use certain portions of our Site and Services, you will
        be required to set up an account. &nbsp;During registration, you will be
        required to provide certain personal data, such as your name, contact
        information, and preferences. &nbsp;You will be solely responsible for
        all information you provide to us. &nbsp;You will also be solely
        responsible for the security of your account credentials and any
        activity (whether authorized or unauthorized) that takes place through
        your account. &nbsp;You agree to notify us immediately of any
        unauthorized uses of your account or any other breach of security.
        &nbsp; You agree not to submit false information to us or anyone else
        through the Site or Services. By creating an account on the Site, you
        consent to receive periodic communication from us.</span
      >
    </p>
    <p className="c7"><span className="c6 c2"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="7">
      <li className="c9 c14 li-bullet-0">
        <span className="c6 c2">NOT INTENDED FOR INDIVIDUALS UNDER EIGHTEEN.</span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c4"
        >THE SITE IS DESIGNED FOR USE BY INDIVIDUALS AGED EIGHTEEN (18) AND
        OLDER. &nbsp;IF YOU ARE UNDER EIGHTEEN (18) YEARS OF AGE, YOU MAY NOT
        USE THE SITE. &nbsp;IF YOU ARE UNDER 18 AND WOULD LIKE TO USE THIS SITE,
        WE WILL NEED TO OBTAIN PARENTAL CONSENT FROM YOUR PARENT OR LEGAL
        GUARDIAN. TO PROVIDE SUCH CONSENT, PLEASE HAVE YOUR PARENT/GUARDIAN
        CONTACT US SO WE CAN DISCUSS FURTHER. IF YOU HAVE </span
      ><span className="c4"
        >KNOWLEDGE OF ANYONE USING THE SITE THAT IS UNDER 18 YEARS OLD, PLEASE
        NOTIFY US AT </span
      ><span className="c13 c4"
        ><a className="c5" href="mailto:privacy@homefounder.com"
          >TEAM@HOMEFOUNDER.COM</a
        ></span
      ><span>.</span>
    </p>
    <p className="c7 c30"><span className="c6 c2"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="8">
      <li className="c9 c14 li-bullet-0">
        <span className="c2">YOUR OBLIGATIONS TO US. &nbsp;</span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c2"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >In exchange for use of the Site and/or any Services, you represent the
        following:</span
      >
    </p>
    <p className="c7"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_5-0 start" start="1">
      <li className="c24 c12 li-bullet-0">
        <span className="c6 c4">You are at least 18 years of age. </span>
      </li>
      <li className="c24 c12 li-bullet-0">
        <span className="c6 c4"
          >You agree not to create false accounts or to provide false
          information. You represent and warrant that the contact information
          and account information you provide is truthful at the time you
          provide it.</span
        >
      </li>
      <li className="c12 c24 li-bullet-0">
        <span className="c6 c4"
          >The security of your account is your responsibility. You agree to
          choose a strong password and keep it private to you. You&rsquo;re
          responsible for any and all activity on your account. If you suspect
          misuse of your account, please contact us immediately.</span
        >
      </li>
      <li className="c24 c12 li-bullet-0">
        <span className="c6 c4"
          >All content and information you provide is owned by you and/or you
          have the right to provide it to us.</span
        >
      </li>
      <li className="c9 c12 li-bullet-0">
        <span className="c6 c4"
          >When using the Site and/or Services, you will obey all applicable
          federal, state and local laws.
        </span>
      </li>
      <li className="c9 c12 li-bullet-0">
        <span className="c6 c4"
          >You will copy information from the Site only for your personal,
          non-commercial purposes.</span
        >
      </li>
      <li className="c9 c12 li-bullet-0">
        <span className="c6 c4"
          >You will not otherwise reproduce, modify, distribute, display or
          otherwise provide access to the Site or its content.</span
        >
      </li>
      <li className="c9 c12 li-bullet-0">
        <span className="c6 c4"
          >You will not use the Site or the Services in any way that is unlawful
          or may harm HomeFounder&rsquo;s business.</span
        >
      </li>
      <li className="c24 c12 li-bullet-0">
        <span className="c6 c4"
          >You will not engage in any of the following activities:</span
        >
      </li>
    </ol>
    <ul className="c0 lst-kix_list_4-1 start">
      <li className="c23 li-bullet-0">
        <span className="c4"
          >Using the Site, Services and/or any information or content available
          through the Site and/or Services in any manner that is not expressly
          permitted under these Terms of Use.</span
        >
      </li>
      <li className="c23 li-bullet-0">
        <span className="c4"
          >Engaging in activity that is unauthorized advertisement or promotion,
          including unauthorized solicitation of users of the Site and/or
          Services.</span
        >
      </li>
      <li className="c23 li-bullet-0">
        <span className="c4"
          >Collecting personal information of other users of the Site and/or
          Services without such user&#39;s consent.</span
        >
      </li>
      <li className="c23 li-bullet-0">
        <span className="c4"
          >Engaging in activity that compromises the Site and/or Services,
          including (without limitation) hacking, IP attacks or transferring any
          viruses, malware or harmful code to the Site or other users of the
          Site or Services.</span
        >
      </li>
      <li className="c23 li-bullet-0">
        <span className="c4"
          >Allowing unauthorized access, whether intentionally or
          unintentionally, to the Site and/or Services.</span
        >
      </li>
      <li className="c23 li-bullet-0">
        <span className="c4"
          >Using or submitting any offensive, threatening or defamatory.</span
        >
      </li>
      <li className="c23 li-bullet-0">
        <span className="c6 c4"
          >Interfering or attempting to interfere with the proper working of the
          Site and/or Services; or</span
        >
      </li>
      <li className="c23 li-bullet-0">
        <span className="c6 c4"
          >Bypassing measures used to prevent or restrict access to the Site
          and/or Services, violating or attempting to violate the security or
          authentication measures of the system, or attempting to prove, scan,
          or test the vulnerability of a system or network without proper
          written authorization from us.</span
        >
      </li>
    </ul>
    <p className="c16"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >The license granted to you under this Terms of Use to access and use
        the Site and Services shall automatically terminate if you violate any
        of these restrictions.
      </span>
    </p>
    <p className="c7"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="9">
      <li className="c9 c14 c33 c18 li-bullet-0">
        <span className="c6 c2">CONFIDENTIALITY. &nbsp;</span>
      </li>
    </ol>
    <p className="c1"><span className="c6 c4"></span></p>
    <p className="c9 c18 c33">
      <span className="c4"
        >The information available on the Site relates to HomeFounder and the
        home buying opportunities it has to offer (collectively, the
        &ldquo;</span
      ><span className="c2 c20">Disclosing Parties</span
      ><span className="c4"
        >&rdquo;). &nbsp;Some of the information provided on the Site or through
        HomeFounder&rsquo;s Services is highly confidential. You hereby agree to
        maintain such information in strict confidence and acknowledge that such
        information is proprietary and confidential, that HomeFounder and its
        affiliates derive independent economic value from such information not
        being generally known, and that such information is subject to
        reasonable efforts by HomeFounder to maintain its secrecy. &nbsp;
      </span>
    </p>
    <p className="c8 c18"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="10">
      <li className="c9 c14 li-bullet-0">
        <span className="c6 c2">ACCESSIBILITY.</span
        ><span className="c6 c2">&nbsp; </span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c4"
        >We value all of our users, and it is our goal to provide an excellent
        experience for all our users, including our users with disabilities. You
        understand and accept, however, that some of the features of the Site
        may not be fully accessible because they are provided by third-parties
        or have errors. If you have comments regarding the accessibility of the
        Site, or would like to report an issue you are experiencing, please
        email us at </span
      ><span className="c13 c4"
        ><a className="c5" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c6 c4"
        >. &nbsp; &nbsp;You expressly agree to attempt to work with us in good
        faith to obtain access to our Site and Services and that you will not
        threaten or assert any claims regarding accessibility against us unless
        you have first provided us with notice of the inaccessibility problem(s)
        and a thirty (30) day period to remedy the problem.</span
      >
    </p>
    <ol className="c0 lst-kix_list_7-0" start="11">
      <li className="c9 c14 li-bullet-0">
        <span className="c2 c10"
          >&nbsp; DISCLAIMERS AND LIMITS ON LIABILITY; WAIVER OF CLASS ACTION
          RIGHTS.</span
        ><span className="c4 c10">&nbsp; </span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c4 c10"
        >THE SITE, AND ALL INFORMATION AND CONTENT AVAILABLE THROUGH THE SITE
        ARE PROVIDED TO YOU ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot;
        BASIS WITHOUT ANY IMPLIED OR EXPRESS WARRANTY OF ANY KIND (INCLUDING,
        WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, SUITABILITY, SECURITY, CORRECTNESS,
        ACCESSIBILITY AND NON-INFRINGEMENT). HOMEFOUNDER, ITS AFFILIATES,
        OFFICERS, DIRECTORS, MEMBERS, MANAGERS, EMPLOYEES, CONTRACTORS, AGENTS,
        AND SUPPLIERS (COLLECTIVELY THE &quot;</span
      ><span className="c2 c10">HOMEFOUNDER PARTIES</span
      ><span className="c6 c4"
        >&quot;) MAKE NO REPRESENTATION OR WARRANTY REGARDING: (A) THE CONTINUED
        AVAILABILITY, RELIABILITY, ACCURACY, ACCESSIBILITY, RESULTS OR
        PERFORMANCE OF THE SITE; (B) THE EXISTENCE (OR ABSENCE) OF ANY VIRUS,
        WORM, MALWARE, MALICIOUS CODE OR OTHER DISABLING DEVICES OR CODE FROM
        ANY SOURCES; (C) THE UNAUTHORIZED ACCESS TO OR USE OF YOUR INFORMATION;
        (D) ANY TECHNICAL FAILURES (INCLUDING HARDWARE OR SOFTWARE FAILURES); OR
        (E) LOSS, USE OR MISUSE OF YOUR DATA.</span
      >
    </p>
    <p className="c9"><span className="c4 c10">&nbsp;</span></p>
    <p className="c17">
      <span className="c6 c4"
        >YOUR USE OF THE SITE IS AT YOUR OWN RISK. YOU AGREE THAT THE
        HOMEFOUNDER PARTIES ARE NOT LIABLE FOR ANY CONSEQUENTIAL, SPECIAL,
        DIRECT, INDIRECT, EXEMPLARY, OR PUNITIVE DAMAGES. IN NO EVENT SHALL THE
        HOMEFOUNDER PARTIES&#39; TOTAL LIABILITY TO YOU FOR ANY DAMAGES, LOSSES,
        FEES AND EXPENSES (INCLUDING ATTORNEYS&#39; FEES), WHETHER IN CONTRACT,
        TORT, OR OTHERWISE, EXCEED THE AMOUNT (IF ANY) PAID BY YOU TO
        HOMEFOUNDER FOR THE USE OF THE SITE WITHIN THE LAST TWELVE MONTHS. IF
        YOU LIVE IN A STATE THAT DOES NOT ALLOW THE WAIVER OF CERTAIN
        WARRANTIES, OR LIMITATIONS OR DAMAGES WAIVERS DESCRIBED IN THIS SECTION,
        SOME OF THESE PROVISIONS MAY NOT APPLY TO YOU. YOU AGREE TO PROMPTLY
        NOTIFY HOMEFOUNDER IN WRITING IF YOU BELIEVE YOU HAVE ANY CLAIM AGAINST
        ANY OF THE HOMEFOUNDER PARTIES, AND, IN ANY EVENT, YOU AGREE THAT ANY
        CLAIM NOT BROUGHT WITHIN ONE YEAR AFTER IT ARISES (OR SUCH SHORTER
        PERIOD UNDER APPLICABLE STATUTES OF LIMITATION) SHALL BE WAIVED AND
        RELEASED. &nbsp;YOU FURTHER AGREE TO RESOLVE ANY AND ALL CLAIMS AGAINST
        THE HOMEFOUNDER PARTIES ON AN INDIVIDUAL BASIS AND KNOWINGLY WAIVE YOUR
        RIGHT TO PARTICIPATE IN ANY CLASS ACTION AGAINST THE HOMEFOUNDER
        PARTIES.</span
      >
    </p>
    <ol className="c0 lst-kix_list_7-0" start="12">
      <li className="c9 c14 li-bullet-0">
        <span className="c2">&nbsp; INDEMNIFICATION. &nbsp;</span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c2"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >You agree to indemnify, defend and hold harmless HomeFounder and its
        third-party service providers, for any and all claims, demands, actions,
        liability, fines, penalties and expenses, whether based on warranty,
        contract, negligence, strict liability or otherwise, that may arise
        from: (a) your breach of these Terms of Use; (b) your access or use of
        the Site and/or Services; (c) your infringement or the infringement of
        any third-party using your account, of any intellectual property right
        of another; and (d) any authorized activity through your account.
        &nbsp;</span
      >
    </p>
    <p className="c7"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="13">
      <li className="c9 c14 li-bullet-0">
        <span className="c2">&nbsp; </span
        ><span className="c2">THIRD-PARTY LINKS. &nbsp;</span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >The Site may contain links to other third-party websites. &nbsp;Such
        third-party websites are maintained by persons or organizations over
        which HomeFounder exercises no control. HomeFounder expressly disclaims
        any responsibility for the content or results from your use of such
        third-party websites.</span
      >
    </p>
    <p className="c7"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="14">
      <li className="c9 c14 li-bullet-0">
        <span className="c2">&nbsp; </span
        ><span className="c2">COPYRIGHT COMPLAINTS. </span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c2"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >HomeFounder respects the intellectual property of others. If you
        believe that your work has been copied in a way that constitutes
        copyright infringement, please provide us with the following
        information.</span
      >
    </p>
    <ul className="c0 lst-kix_list_3-0 start">
      <li className="c9 c19 li-bullet-0">
        <span className="c6 c4"
          >An electronic or physical signature of the person authorized to act
          on behalf of the owner of the copyright interest;</span
        >
      </li>
      <li className="c9 c19 li-bullet-0">
        <span className="c6 c4"
          >Description of the copyrighted work that you claim has been
          infringed;</span
        >
      </li>
      <li className="c9 c19 li-bullet-0">
        <span className="c6 c4"
          >The location on the Site of the material that you claim is
          infringing;</span
        >
      </li>
      <li className="c9 c19 li-bullet-0">
        <span className="c6 c4"
          >Your address, telephone number and e-mail address;</span
        >
      </li>
      <li className="c9 c19 li-bullet-0">
        <span className="c6 c4"
          >A statement that your claim of infringement is based on a good faith
          belief; and</span
        >
      </li>
      <li className="c9 c19 li-bullet-0">
        <span className="c6 c4"
          >A statement made under penalty of perjury that the information you
          have provided is accurate and that you are the copyright owner or
          authorized to act on the copyright owner&#39;s behalf.</span
        >
      </li>
    </ul>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c25">
      <span className="c4"
        >Our copyright agent for notice of claims of copyright infringement on
        the Site can be reached at </span
      ><span className="c4 c13"
        ><a className="c5" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c4">. &nbsp; &nbsp; </span>
    </p>
    <p className="c9"><span className="c2">&nbsp; </span></p>
    <ol className="c0 lst-kix_list_7-0" start="15">
      <li className="c9 c14 li-bullet-0">
        <span className="c2 c10"
          >CONSENT TO RECEIVE ELECTRONIC NOTIFICATIONS. &nbsp;</span
        >
      </li>
    </ol>
    <p className="c7"><span className="c6 c2"></span></p>
    <p className="c9">
      <span className="c4 c10"
        >Electronic communication is the most effective and timely way to
        provide the users of the Site and/or Services with any optional or
        required notifications and disclosures. In some circumstances, however,
        state and/or federal laws may require us to send you disclosures or
        communications in paper format unless you have affirmatively consented
        to receiving electronic notifications only in advance of the
        notification. Through this Agreement, pursuant to 15 U.S.C. &sect; 7001,
        you hereby affirmatively consent to receive electronic notifications and
        disclosures from us only (without requiring a paper copy) and you
        represent that, to date, you have not withdrawn such consent. You have
        the right to change your mind and withdraw your consent at any time. If
        you would </span
      ><span className="c4"
        >like to withdraw your consent to receive electronic notifications
        and/or would like to request a paper copy of any electronic
        notifications you receive, please contact us at </span
      ><span className="c13 c4"
        ><a className="c5" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c4">. T</span
      ><span className="c6 c4"
        >o receive electronic records, you will need access to a smart phone,
        tablet, laptop or computer with Internet access and either email
        software (such as Microsoft Outlook) or access to a web-based electronic
        mail software Site, such as Gmail, Yahoo or other electronic mail
        provider.</span
      >
    </p>
    <p className="c8"><span className="c6 c2"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="16">
      <li className="c3 li-bullet-0">
        <span className="c2">&nbsp; SEVERABILITY.</span
        ><span className="c4">&nbsp;</span>
      </li>
    </ol>
    <p className="c8"><span className="c26 c4 c20 c10"></span></p>
    <p className="c9">
      <span className="c4"
        >If any term or provision in these Terms of Use is found to be void,
        against public policy, or unenforceable by a court of competent
        jurisdiction and such finding or order becomes final with all appeals
        exhausted, then the offending provision shall be deemed modified to the
        extent necessary to make it valid and enforceable. If the offending
        provision cannot be so modified, then the same shall be deemed stricken
        from these Terms of Use in its entirety and the remainder of these Terms
        of Use shall survive with the said offending provision eliminated.</span
      >
    </p>
    <ol className="c0 lst-kix_list_7-0" start="17">
      <li className="c9 c14 li-bullet-0">
        <span className="c6 c2">&nbsp; AVAILABILITY.</span
        ><span className="c6 c4">&nbsp;</span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c4"
        >HOMEFOUNDER CANNOT GUARANTEE THE SITE WILL BE AVAILABLE OR SECURE 100%
        OF THE TIME BECAUSE PUBLIC NETWORKS, SUCH AS THE INTERNET, OCCASIONALLY
        EXPERIENCE DISRUPTIONS AND BECAUSE IT IS HOSTED BY A THIRD-PARTY
        PROVIDER. ALTHOUGH WE STRIVE TO PROVIDE THE MOST RELIABLE SITE
        REASONABLY POSSIBLE, INTERRUPTIONS, THIRD PARTY INTERFERENCE AND DELAYS
        DO OCCUR. HOMEFOUNDER AND ITS SERVICE PROVIDERS EXPRESSLY DISCLAIM ANY
        LIABILITY FOR DAMAGES RESULTING FROM SUCH PROBLEMS.</span
      >
    </p>
    <p className="c7 c27"><span className="c6 c2"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="18">
      <li className="c9 c14 li-bullet-0">
        <span className="c2 c20 c10 c26">&nbsp; </span
        ><span className="c6 c2">CONTROLLING LAW AND VENUE.</span
        ><span className="c6 c4">&nbsp;</span>
      </li>
    </ol>
    <p className="c7"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c4"
        >It is understood and agreed that all the construction and
        interpretation of these Terms of Use and the relationship between the
        parties shall at all times and in all respects be governed by the
        internal laws of the State of Massachusetts</span
      ><span className="c2">,</span
      ><span className="c6 c4"
        >&nbsp;without giving effect to the conflict of laws provisions thereof.
        Venue of any action brought to enforce or relating to these Terms of Use
        or arising out of the relationship between the parties shall be brought
        exclusively in the courts located in Boston, Massachusetts.</span
      >
    </p>
    <p className="c9"><span className="c6 c2">&nbsp;</span></p>
    <ol className="c0 lst-kix_list_7-0" start="19">
      <li className="c3 c18 li-bullet-0">
        <span className="c6 c2">FORCE MAJUERE</span
        ><span className="c6 c4">. &nbsp;</span>
      </li>
    </ol>
    <p className="c8 c18"><span className="c6 c4"></span></p>
    <p className="c9 c18">
      <span className="c4 c10"
        >HomeFounder will not be liable for, or be considered to be in breach of
        or default under these Terms on account of any delay or failure to
        perform as required by these Terms as a result of any cause or condition
        beyond HomeFounder&rsquo;s reasonable control, so long as it uses all
        commercially reasonable efforts to avoid or remove such causes of
        non-performance or delay.</span
      >
    </p>
    <p className="c8"><span className="c6 c4"></span></p>
    <ol className="c0 lst-kix_list_7-0" start="20">
      <li className="c3 li-bullet-0">
        <span className="c6 c2">&nbsp; QUESTIONS.</span>
      </li>
    </ol>
    <p className="c8"><span className="c6 c4"></span></p>
    <p className="c9">
      <span className="c6 c4"
        >If you have any questions about us, the Site, the Services, our Privacy
        Policy, or these Terms and Conditions, you may contact us at:</span
      >
    </p>
    <p className="c8 c31" id="h.3znysh7"><span className="c6 c4"></span></p>
    <p className="c25 c31">
      <span className="c6 c4 c11">HomeFounder</span><span className="c6 c4"><br /></span
      ><span className="c6 c4 c11">by Gemini Studio, Inc.</span
      ><span className="c6 c4"><br /></span
      ><span className="c6 c4 c11"
        >6 Liberty Square, PO Box 266, Boston, MA 02109</span
      >
    </p>
    <p className="c28">
      <span className="c13 c4 c11"
        ><a className="c5" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span className="c4 c11">&nbsp;</span>
    </p>
  </div>
        </Policy>
    </Layout>
  )
}

export default TermsPage

export const Head: HeadFC = () => <SEO title="Terms of Service"/>
