import * as React from "react"
import strings from "../../i18n"

import "./policy.scss"

const Policy = ({children}) => {
  return (
    <div className="policy">
      <div className="container">
        {children}
      </div>
    </div>
  )
}

export default Policy
